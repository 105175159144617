import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap';

function App() {
  const [data, setData] = useState([]);
  const token = localStorage.getItem('token');
  
  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_COMPONENT_URL || 'http://localhost:3001/api';
    axios.get(`${apiUrl}/site/alllist`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => setData(response.data))
    .catch(error => console.error('Error fetching data:', error));
  }, [token]);

  return (
    <main className="mb-4">
      <section id="Themes" className="Themes">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h1>Liste des sites</h1>
          </div>
          <div className="row">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>User ID</th>
                  <th>Theme ID</th>
                  <th>Nom</th>
                  <th>URL</th>
                  <th>Date de création</th>
                  <th>Date de mise à jour</th>
                </tr>
              </thead>
              <tbody>
                {data.map(site => (
                  <tr key={site.id}>
                    <td>{site.id}</td>
                    <td>{site.userId}</td>
                    <td>{site.themeId}</td>
                    <td>{site.name}</td>
                    <td>{site.url}</td>
                    <td>{new Date(site.createdAt).toLocaleDateString()}</td>
                    <td>{new Date(site.updatedAt).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </section>
    </main>
  );
}

export default App;
