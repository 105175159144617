import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Themes() {
  const [themes, setThemes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const token = localStorage.getItem('token');

  const getSignedUrl = useCallback(async (fileName, themeName) => {
    try {
      const apiUrl = process.env.REACT_APP_API_COMPONENT_URL || 'http://localhost:3001/api';
      const response = await axios.post(`${apiUrl}/themes/getimagetheme`, { fileName, themeName }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data.url;
    } catch (error) {
      console.error('Error fetching signed URL:', error);
      return '';
    }
  }, [token]);

  const fetchThemes = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_COMPONENT_URL || 'http://localhost:3001/api';
      const response = await axios.get(`${apiUrl}/themes/list`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = response.data;
      const themesWithSignedUrls = await Promise.all(
        data.map(async (theme) => {
          const signedUrl = await getSignedUrl(`${theme.name}-cover.jpg`, theme.name);
          return { ...theme, signedUrl };
        })
      );
      setThemes(themesWithSignedUrls);
    } catch (error) {
      console.error('Error fetching themes:', error);
    } finally {
      setLoading(false);
    }
  }, [getSignedUrl, token]);

  useEffect(() => {
    fetchThemes();
  }, [fetchThemes]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredThemes = themes.filter(theme => 
    theme.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    theme.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    theme.tags.toLowerCase().includes(searchTerm.toLowerCase()) ||
    theme.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
    theme.price_id_stripe.toLowerCase().includes(searchTerm.toLowerCase()) ||
    theme.price.toString().includes(searchTerm) ||
    theme.urlThemes.toLowerCase().includes(searchTerm.toLowerCase()) ||
    theme.etat.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <main className="mb-4">
      <section id="Themes" className="Themes">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h1>Themes</h1>
          </div>
          <div className="row mb-3">
            <Link to="/addtheme">
              <Button className="add-btn" block>Ajouter un thème <i className="bi bi-plus-circle-fill"></i></Button>
            </Link>
          </div>

          <div className="row mb-3">
            <Form.Control 
              type="text" 
              placeholder="Search..." 
              value={searchTerm} 
              onChange={handleSearchChange} 
            />
          </div>
          <div className="row">
            <div style={{ overflowX: 'auto' }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Cover Photo</th>
                    <th>Description</th>
                    <th>Tags</th>
                    <th>Version</th>
                    <th>Category</th>
                    <th>Id Prix stripe</th>
                    <th>Price</th>
                    <th>UrlThemes</th>                  
                    <th>Etat</th>
                    <th>Action</th>                 
                  </tr>
                </thead>
                <tbody>
                  {filteredThemes.map(theme => (
                    <ThemeRow key={theme.id} theme={theme} />
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

function ThemeRow({ theme }) {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const isLongDescription = theme.description.length > 30;

  return (
    <tr>
      <td>{theme.id}</td>
      <td>{theme.name}</td>
      <td>
        <a href={theme.signedUrl} target="_blank" rel="noopener noreferrer">
          <img src={theme.signedUrl} alt={theme.name} style={{ maxWidth: '100px', maxHeight: '100px' }} />
        </a>
      </td>
      <td>
        {isLongDescription ? (
          <>
            {showFullDescription ? theme.description : `${theme.description.substring(0, 30)}...`}
            <Button block variant="link" onClick={toggleDescription}>
              {showFullDescription ? 'Show less' : 'Show more'}
            </Button>
          </>
        ) : (
          theme.description
        )}
      </td>
      <td>{theme.tags}</td>
      <td>{theme.version}</td>
      <td>{theme.category}</td>
      <td>{theme.price_id_stripe}</td>
      <td>{theme.price}</td>
      <td>{theme.urlThemes}</td>
      <td>{theme.etat}</td>
      <td>
        <Link to={`/updatetheme/${theme.id}`}>
          <Button className="edit-btn" block>Update</Button>
        </Link>
      </td>
    </tr>
  );
}

export default Themes;
