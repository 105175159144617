import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingSpinner = ({ isLoading }) => {
  return (
    isLoading && (
      <div className="spinner-overlay">
        <div className="spinner-container">
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading...</span>
          </Spinner>
          <p className="mt-2">Please wait...</p>
        </div>
      </div>
    )
  );
};

export default LoadingSpinner;
