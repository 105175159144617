import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col } from 'react-bootstrap';

function Home() {
  return (
    <main className="mb-4">
      <section id="home" className="home">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h1>ADMIN</h1>
          </div>
          <div className="row">
            <Col md={3} >
              <Link to="/users">
                <Button block={true} className="btn-block">USERS</Button>
              </Link>
            </Col>
            <Col md={3}>
              <Link to="/themes">
                <Button block={true} className="btn-block">THEMES</Button>
              </Link>
            </Col>
            <Col md={3}>
              <Link to="/sites">
                <Button block={true} className="btn-block" >SITES</Button>
              </Link>
            </Col>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
