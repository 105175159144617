import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';

function AddUser() {
  const [userData, setUserData] = useState({
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    address: '',
    dateOfBirth: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData.password.length < 10) {
      setError('Le mot de passe doit contenir au moins 10 caractères');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const apiUrl = process.env.REACT_APP_API_AUTH_URL || 'http://localhost:3100/api';
      await axios.post(`${apiUrl}/auth/signup`, userData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      setSuccess('Utilisateur ajouté avec succès');
      setError('');
      setUserData({
        username: '',
        password: '',
        firstName: '',
        lastName: '',
        address: '',
        dateOfBirth: '',
      });
    } catch (err) {
      setError('Une erreur s\'est produite lors de l\'ajout de l\'utilisateur');
      setSuccess('');
    }
  };

  return (
    <main className="mb-4">
      <section id="users" className="users">
        <Container className="mb-4">
          <Row>
            <Col>
              <div className="section-title text-center">
                <h2>Ajouter un utilisateur</h2>
              </div>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="username">
                  <Form.Label>Nom d'utilisateur</Form.Label>
                  <Form.Control
                    type="text"
                    name="username"
                    value={userData.username}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.Label>Mot de passe</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={userData.password}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="firstName">
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={userData.firstName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="lastName">
                  <Form.Label>Nom de famille</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={userData.lastName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="address">
                  <Form.Label>Adresse</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={userData.address}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="dateOfBirth">
                  <Form.Label>Date de naissance</Form.Label>
                  <Form.Control
                    type="date"
                    name="dateOfBirth"
                    value={userData.dateOfBirth}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <div className="d-flex justify-content-center mt-4">
                    {error && <Alert variant="danger">{error}</Alert>}
                    {success && <Alert variant="success">{success}</Alert>}
                </div>

                <div className="d-flex justify-content-center mt-4">
                    <Button variant="primary" type="submit" className="save-btn">
                        Ajouter
                    </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}

export default AddUser;
