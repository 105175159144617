// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import Users from './pages/Users';
import Details from './pages/Details';
import Navbar from './pages/navbar';
import Themes from './pages/Themes';
import Sites from './pages/Sites';
import Addtheme from './pages/addtheme';
import updatetheme from './pages/updatetheme';
import Login from './pages/Login';
import ProtectedRoute from './ProtectedRoute';
import AddUser from './pages/Adduser';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/vendor/glightbox/css/glightbox.min.css';
import './assets/vendor/remixicon/remixicon.css';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      verifyToken(token);
    }
  }, []);

  const verifyToken = async (token) => {
    const apiUrl = process.env.REACT_APP_API_AUTH_URL || 'http://localhost:3100/api';
    try {
      const response = await fetch(`${apiUrl}/auth/protected`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setIsAuthenticated(true);
      } else {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        window.location.href = '/login';
      }
    } catch (error) {
      console.error('Error verifying token:', error);
      localStorage.removeItem('token');
      setIsAuthenticated(false);
      window.location.href = '/login';
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <Navbar isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
      <div>
        <Switch>
          <ProtectedRoute exact path="/login" component={Login} isAuthenticated={isAuthenticated} />
          {isAuthenticated ? (
            <>
              <Route exact path="/" component={Home} />
              <Route exact path="/users" component={Users} />
              <Route exact path="/AddUser" component={AddUser} />
              <Route exact path="/Themes" component={Themes} />
              <Route exact path="/Sites" component={Sites} />
              <Route exact path="/addtheme" component={Addtheme} />
              <Route exact path="/details/:user" component={Details} />
              <Route exact path="/updatetheme/:id" component={updatetheme} />
            </>
          ) : (
            <Redirect to="/login" />
          )}
        </Switch>
      </div>
    </Router>
  );
};

export default App;
