// src/pages/Login.js
import React, { useState } from 'react';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode'; // Importation nommée correcte

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    const apiUrl = process.env.REACT_APP_API_AUTH_URL || 'http://localhost:3100/api';
    const response = await fetch(`${apiUrl}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      const data = await response.json();
      const decodedToken = jwtDecode(data.token);

      if (decodedToken.role === 'admin') {
        localStorage.setItem('token', data.token);
        window.location.href = '/'; // Redirection vers la page d'accueil après une connexion réussie
      } else {
        setErrorMessage('Permissions insuffisantes');
      }
    } else {
      setErrorMessage('Échec de l\'authentification');
    }
  };

  return (
    <main className="mb-4">
      <section id="login" className="login">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h1>Login</h1>
          </div>
          <Row className="justify-content-md-center">
            <Col md={6}>
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <Form onSubmit={handleLogin}>
                <Form.Group controlId="formUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Button className="login-btn" type="submit" block>
                  Login
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </section>
    </main>
  );
};

export default Login;
