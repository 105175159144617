import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Table, Modal, Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

function Users() {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]); // Ajouter un état pour les rôles
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingUser, setEditingUser] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [editedFields, setEditedFields] = useState({});
  const usersPerPage = 20;
  const [deleteMessage, setDeleteMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editMessage, setEditMessage] = useState('');
  const token = localStorage.getItem('token');
  const history = useHistory();

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_AUTH_URL || 'http://localhost:3100/api';
    axios.get(`${apiUrl}/auth/listusers`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => setUsers(response.data.results))
    .catch(error => console.error('Error fetching data:', error));
  }, [token]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_AUTH_URL || 'http://localhost:3100/api';
    axios.get(`${apiUrl}/roles/getListRoles`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => setRoles(response.data.results))
    .catch(error => console.error('Error fetching roles:', error));
  }, [token]);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users
    .filter(user =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.date_of_birth.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.last_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(indexOfFirstUser, indexOfLastUser);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const handleSearch = event => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleEdit = user => {
    setEditingUser(user);
    setEditedFields({
      username: user.username,
      first_name: user.first_name,
      last_name: user.last_name,
      address: user.address,
      role_name: user.role_name, // Ajouter le champ role_name
    });
  };

  const handleSave = () => {
    const apiUrl = process.env.REACT_APP_API_AUTH_URL || 'http://localhost:3100/api';
    axios.post(`${apiUrl}/auth/updateUser`, editedFields, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      if (response.status === 200) {
        setEditMessage(`Modification de l'utilisateur ${editedFields.username} effectuée.`);

        // Appel API supplémentaire pour mettre à jour le rôle de l'utilisateur
        axios.post(`${apiUrl}/roles/updateRoleUser`, {
          username: editedFields.username,
          newRole: editedFields.role_name
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          if (response.status === 200) {
            console.log(`Role de l'utilisateur ${editedFields.username} mis à jour avec succès.`);
          } else {
            console.error('Erreur lors de la mise à jour du rôle.');
          }
        })
        .catch(error => {
          console.error('Erreur lors de la mise à jour du rôle:', error);
        });

        setTimeout(() => {
          setEditMessage('');
        }, 3000);
      } else {
        console.error('Error updating user information');
      }
    })
    .catch(error => {
      console.error('Error updating user:', error);
    });

    setEditingUser(null);
  };

  const handleDelete = user => {
    const apiUrl = process.env.REACT_APP_API_AUTH_URL || 'http://localhost:3100/api';
    const usernameToDelete = user.username;
    const requestBody = { username: usernameToDelete };
    axios.post(`${apiUrl}/auth/removeuser`, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      if (response.status === 200) {
        setDeleteMessage(`L'utilisateur avec le username ${usernameToDelete} a été supprimé.`);
      } else {
        console.error(`Failed to delete user with username ${usernameToDelete}.`);
      }
      setShowModal(false);
    })
    .catch(error => {
      console.error('Error deleting user:', error);
    });
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setEditedFields(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const redirectToAddUser = () => {
    history.push('/AddUser');
  };

  return (
    <main className="mb-4">
      <section id="users" className="users">
        <Container>
          <Row>
            <Col>
              <div className="section-title">
                <h2>Liste des Users</h2>
              </div>
              <Button variant="primary" onClick={redirectToAddUser} className="mb-3">
                Ajouter un utilisateur
              </Button>
              <Modal show={showModal} onHide={() => setShowModal(false)} style={{ zIndex: 9999 }}>
                <Modal.Header closeButton>
                  <Modal.Title>Confirmation de suppression</Modal.Title>
                </Modal.Header>
                <Modal.Body>Êtes-vous sûr de vouloir supprimer cet utilisateur "{deletingUser && deletingUser.username}" ?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowModal(false)}>Non</Button>
                  <Button variant="danger" onClick={() => handleDelete(deletingUser)}>Oui</Button>
                </Modal.Footer>
              </Modal>
              {editMessage && <p className="success-message">{editMessage}</p>}
              {deleteMessage && <p className="success-message">{deleteMessage}</p>}
              <Form.Group controlId="search" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Rechercher..."
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </Form.Group>
              <Table striped bordered hover responsive="md">
                <thead className="thead-dark">
                  <tr>
                    <th>ID</th>
                    <th>Username</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Address</th>
                    <th>Date of Birth</th>
                    <th>Roles</th> 
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map(user => (
                    <tr key={user.id}>
                      <td>{user.id}</td>
                      <td>
                        <a href={`/details/${user.username}`} rel="noreferrer" target="_blank">{user.username}</a>
                        <a href={`/details/${user.username}`} rel="noreferrer" target="_blank"><i className="bi bi-info-square-fill"></i></a>
                      </td>
                      <td>
                        {editingUser === user ? (
                          <Form.Control
                            type="text"
                            name="first_name"
                            value={editedFields.first_name}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        ) : (
                          user.first_name
                        )}
                      </td>
                      <td>
                        {editingUser === user ? (
                          <Form.Control
                            type="text"
                            name="last_name"
                            value={editedFields.last_name}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        ) : (
                          user.last_name
                        )}
                      </td>
                      <td>
                        {editingUser === user ? (
                          <Form.Control
                            type="text"
                            name="address"
                            value={editedFields.address}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        ) : (
                          user.address
                        )}
                      </td>
                      <td>{user.date_of_birth}</td>
                      <td> 
                        {editingUser === user ? (
                          <Form.Control as="select" name="role_name" value={editedFields.role_name} onChange={handleInputChange} className="form-select">
                            {roles.map(role => (
                              <option key={role.id} value={role.name}>{role.name}</option>
                            ))}
                          </Form.Control>
                        ) : (
                          user.role_name
                        )}
                      </td>
                      <td>{user.createdAt}</td>
                      <td>{user.updatedAt}</td>
                      <td>
                        {editingUser === user ? (
                          <>
                            <Button block={true} variant="success" size="sm" className="save-btn" onClick={() => handleSave()}>Save</Button>
                            <Button block={true} variant="secondary" size="sm" className="cancel-btn" onClick={() => setEditingUser(null)}>Cancel</Button>
                          </>
                        ) : (
                          <>
                            <Button block={true} variant="primary" size="sm" className="edit-btn" onClick={() => handleEdit(user)}>Edit</Button>
                            <Button block={true} variant="danger" size="sm" className="delete-btn" onClick={() => { setDeletingUser(user); setShowModal(true); }}>Delete</Button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div>
                {Array.from({ length: Math.ceil(users.length / usersPerPage) }, (_, i) => (
                  <Button key={i} block={true} variant="secondary" onClick={() => paginate(i + 1)}>{i + 1}</Button>
                ))}
              </div>
              
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}

export default Users;
