import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap';

const Details = (props) => {
  const { user } = props.match.params;
  const [userData, setUserData] = useState(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    // Exécuter la requête
    const apiUrl = process.env.REACT_APP_API_COMPONENT_URL || 'http://localhost:3001/api';
    axios.post(`${apiUrl}/site/list`, { username: user }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => setUserData(response.data))
    .catch(error => console.error('Erreur :', error));
  }, [user, token]);

  return (
    <main className="mb-4">
      <section id="users" className="users">
        <div className="container">
          <div className="row">
            <div className="section-title">
              <h2>Page des détails {user}</h2>
            </div>
            <p>Nombre de sites du client {user} : {userData ? userData.length : 0}</p>
            {userData && (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Nom du site</th>
                    <th>URL</th>
                    <th>Nom du Thème</th>
                    <th>Date de création</th>
                    <th>Date de dernière mise à jour</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.map(site => (
                    <tr key={site.id}>
                      <td>{site.name}</td>
                      <td><a href={'https://' + site.url} target="_blank" rel="noopener noreferrer">{site.url}</a></td>
                      <td>{site.themeName}</td>
                      <td>{new Date(site.createdAt).toLocaleDateString()}</td>
                      <td>{new Date(site.updatedAt).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Details;
