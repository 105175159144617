// pages/addtheme.js
import React, { useState } from 'react';
import axios from 'axios';
import { Form, Container, Row, Col, Alert } from 'react-bootstrap';
import LoadingSpinner from '../components/LoadingSpinner'; // Import du composant spinner
import 'bootstrap/dist/css/bootstrap.min.css';

const Addtheme = () => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    version: '',
    coverPhoto: '',
    tags: '',
    price_id_stripe: '',
    urlThemes: '',
    category: 'Vitrine',  // Default value is 'Vitrine'
    etat: 'archiver'      // Default value is 'archiver'
  });

  const [coverPhoto, setCoverPhoto] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State for the spinner
  const token = localStorage.getItem('token');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCoverPhotoChange = (e) => {
    setCoverPhoto(e.target.files[0]);
  };

  const handleFileContentChange = (e) => {
    setFileContent(e.target.files[0]);
  };

  const validateForm = () => {
    const newErrors = {};
    const versionPattern = /^\d+\.\d+\.\d+$/;

    if (!formData.name) {
      newErrors.name = 'Name is required';
    } else if (formData.name.length > 50) {
      newErrors.name = 'Name must be at most 50 characters long';
    }

    if (!formData.description) {
      newErrors.description = 'Description is required';  // Validation pour le champ description
    }

    if (!versionPattern.test(formData.version)) {
      newErrors.version = 'Version must be in the format x.x.x';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);  // Show spinner when the request starts
    try {
      const apiUrl = process.env.REACT_APP_API_COMPONENT_URL || 'http://localhost:3001/api';
      const formDataToSend = new FormData();

      formDataToSend.append('name', formData.name);
      formDataToSend.append('description', formData.description);  // Champ description
      formDataToSend.append('version', formData.version);
      formDataToSend.append('tags', formData.tags);
      formDataToSend.append('price_id_stripe', formData.price_id_stripe);
      formDataToSend.append('urlThemes', formData.urlThemes);
      formDataToSend.append('category', formData.category);
      formDataToSend.append('etat', formData.etat);

      if (coverPhoto) {
        formDataToSend.append('coverPhoto', coverPhoto);
      }

      if (fileContent) {
        formDataToSend.append('file', fileContent);
      }

      const response = await axios.post(`${apiUrl}/themes/createTheme`, formDataToSend, {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200 || response.status === 201) {
        setMessage('Theme created successfully.');
        setMessageType('success');
      } else {
        setMessage(response.data.message || 'An error occurred while creating the theme.');
        setMessageType('error');
      }
    } catch (error) {
      setMessage('An error occurred while creating the theme.');
      setMessageType('error');
    } finally {
      setIsLoading(false);  // Hide spinner once the request is finished
    }
  };

  return (
    <main className="mb-4">
      <section id="addtheme" className="addtheme">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={8}>
              <div className="section-title">
                <h2>Add Themes</h2>
              </div>
              {message && (
                <Alert variant={messageType === 'success' ? 'success' : 'danger'} className="mt-3">
                  {message}
                </Alert>
              )}
              {/* Display loading spinner as overlay */}
              <LoadingSpinner isLoading={isLoading} />

              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                    required
                  />
                  <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    rows="5"
                    isInvalid={!!errors.description}  
                    required  
                  />
                  <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="version">
                  <Form.Label>Version</Form.Label>
                  <Form.Control
                    type="text"
                    name="version"
                    value={formData.version}
                    onChange={handleChange}
                    isInvalid={!!errors.version}
                  />
                  <Form.Control.Feedback type="invalid">{errors.version}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="coverPhoto">
                  <Form.Label>Cover Photo</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleCoverPhotoChange}
                    className="form-control"
                  />
                </Form.Group>

                <Form.Group controlId="fileContent">
                  <Form.Label>File Content</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleFileContentChange}
                    className="form-control"
                  />
                </Form.Group>

                <Form.Group controlId="tags">
                  <Form.Label>Tags</Form.Label>
                  <Form.Control
                    type="text"
                    name="tags"
                    value={formData.tags}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="price_id_stripe">
                  <Form.Label>Price ID Stripe</Form.Label>
                  <Form.Control
                    type="text"
                    name="price_id_stripe"
                    value={formData.price_id_stripe}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="urlThemes">
                  <Form.Label>Theme URL</Form.Label>
                  <Form.Control
                    type="text"
                    name="urlThemes"
                    value={formData.urlThemes}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="category">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                  >
                    <option value="Ecommerce">Ecommerce</option>
                    <option value="Blog">Blog</option>
                    <option value="Vitrine">Vitrine</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="etat">
                  <Form.Label>Etat</Form.Label>
                  <Form.Control
                    as="select"
                    name="etat"
                    value={formData.etat}
                    onChange={handleChange}
                  >
                    <option value="archiver">Archiver</option>
                    <option value="publier">Publier</option>
                  </Form.Control>
                </Form.Group>

                <div className="text-center mt-3">
                  <button className="add-btn" type="submit">Create Theme</button>
                </div>
                {message && (
                  <Alert variant={messageType === 'success' ? 'success' : 'danger'} className="mt-3">
                    {message}
                  </Alert>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Addtheme;
