import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const Navbar = ({ isAuthenticated, handleLogout }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top" style={{ backgroundColor: '#032654' }}>
      <div className="container px-4 px-lg-5">
        <Link to="/">
          <Button className="btn-block" block={true}>Home</Button>
        </Link>
        {isAuthenticated && (
          <Button
            className="logout-btn"
            block={true}
            onClick={handleLogout}
          >
            Logout
          </Button>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
